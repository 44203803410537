<template>
  <div>
    <div class="field">
      <label class="label">Title</label>
      <div class="control">
        <div class="select">
          <select v-model="model[prefix + '_title']">
            <option v-for="c in titleOptions" :value="c" :key="c">{{ c }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="field">
      <label class="label">First name</label>
      <div class="control">
        <input type="text" class="input" v-model="model[prefix + '_first_name']" />
      </div>
    </div>

    <div class="field">
      <label class="label">Last name</label>
      <div class="control">
        <input type="text" class="input" v-model="model[prefix + '_last_name']" />
      </div>
    </div>

    <div class="field">
      <label class="label">Email</label>
      <div class="control">
        <input type="text" class="input" v-model="model[prefix + '_email']" />
      </div>
    </div>

    <div class="field">
      <label class="label">Phone</label>
      <div class="control">
        <input type="text" class="input" v-model="model[prefix + '_phone']" />
      </div>
    </div>
    
    <div class="field">
        <label class="label">Address 1</label>
        <div class="control">
            <input type="text" class="input" v-model="model[prefix + '_address1']" />
            <small class="help counter">
                {{fieldCharCount(model[prefix + '_address1'].length,35)}}
            </small>
        </div>
        <p v-if="fieldWarningMessage(model[prefix + '_address1'].length,35)" class="help is-danger">
            You are going over the suggested limit
        </p>
    </div>

    <div class="field">
        <label class="label">Address 2</label>
        <div class="control">
            <input type="text" class="input" v-model="model[prefix + '_address2']" />
            <small class="help counter">
                {{fieldCharCount(model[prefix + '_address2'].length,35)}}
            </small>
        </div>
        <p v-if="fieldWarningMessage(model[prefix + '_address2'].length,35)" class="help is-danger">
            You are going over the suggested limit
        </p>
    </div>

    <div class="field">
      <label class="label">Town</label>
      <div class="control">
        <input type="text" class="input" v-model="model[prefix + '_town']" />
      </div>
    </div>

    <div class="field">
      <label class="label">County</label>
      <div class="control">
        <input type="text" class="input" v-model="model[prefix + '_county']" />
      </div>
    </div>

    <div class="field">
      <label class="label">Country (2 Digit code)</label>
      <div class="control">
        <input type="text" class="input" v-model="model[prefix + '_country']" />
      </div>
    </div>

    <div class="field">
        <label class="label">Postcode</label>
        <div class="control">
            <input type="text" class="input" v-model="model[prefix + '_postcode']" />
            <small class="help counter">
                {{fieldCharCount(model[prefix + '_postcode'].length,10)}}
            </small>
        </div>
        <p v-if="fieldWarningMessage(model[prefix + '_postcode'].length,10)" class="help is-danger">
            You are going over the suggested limit
        </p>
    </div>
  </div>
</template>
 
<script>
import { baseMixin } from "../../../mixins/baseMixin.js";
export default {
  name: "fulfilment-address-edit",
  props: {
    model: {
      type: Object,
      required: true
    },
    prefix: {
      type: String,
      required: true
    }
  },
  mixins: [baseMixin],
  data: function() {
    return {
      APIUrl: process.env.VUE_APP_APIURL,
      titleOptions: ["Mr", "Mrs", "Miss", "Dr", "Master", "Ms"]
    };
  },
  methods: {
  }
};
</script>